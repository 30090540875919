form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto;
  }
  
  input, select, button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  h1 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* フォーム上部の画像スタイル */
.form-header-image {
  display: block;
  margin: 0 auto 20px; /* 中央に配置し、下に余白を追加 */
  max-width: 100%; /* 幅をフォームに合わせて調整 */
  height: auto; /* 画像のアスペクト比を維持 */
}
